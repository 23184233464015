import Carousel from 'react-img-carousel';
import './react-img-carousel.css'
import { useState,useEffect} from 'react';
// import Img, { CloudimageProvider } from 'react-cloudimage-responsive'
import'react-img-carousel/lib/carousel.css'

const ReactImgCarousel=(props)=>{
    const [carouselHeight,setCarouselHeight]=useState('550px')
    const updateCarouselHeight=()=>{
        window.innerWidth<650?setCarouselHeight('400px'):setCarouselHeight('550px')
    }
    const imageWidth=window.innerWidth<=400?400:window.innerWidth<=650?650:window.innerWidth<=800?800:window.innerWidth<=1000?1000:1200

    useEffect(()=>{
        window.addEventListener('resize',updateCarouselHeight);
        return ()=>{
            window.removeEventListener('resize',updateCarouselHeight)
        }
    })
    const data=props.data.map((pic,ind)=>{
        const clUrl='https://ccbrwoluba.cloudimg.io/'+pic.src.substr(8)+'?w='+imageWidth+'&func=fit&bg_color=000'
        return (
            <div style={{height: carouselHeight, display: 'flex'}} key={ind} className='carousel-img-div'> 
                <img className='carousel-img' src={clUrl} alt={props.title}/>
                    {/* <Img src={pic.src} alt={props.title} ratio={1.5}/> */}
            </div>)
    })

    const cloudimageConfig = {
        token: 'ahjrfsvlkq',
        doNotReplaceURL: true,
        ImageSizeAttributes: 'take-ratio',
        maxWidth: '100%',
        maxHeight: '100%'
    };

    const width=window.innerWidth<650;

    return (
        // <CloudimageProvider config={cloudimageConfig}>
            <Carousel height={carouselHeight} slideHeight={carouselHeight} slideWidth='100%' lazyLoad={true} cellPadding={ 5 }>
                {data}
            </Carousel>
        // </CloudimageProvider>
    )
}

export default ReactImgCarousel
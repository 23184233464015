import React,{CSSProperties} from 'react'
import axios from 'axios'
import DestinationCard from './destinations.jsx';
import HomePageHeader from './HomePageHeader.jsx';
import { useNavigate,useParams} from "react-router-dom";
import HashLoader from "react-spinners/HashLoader"
import Footer from './Footer.jsx';
import {isMobile} from 'react-device-detect'
import CryptoJS from 'crypto-js'
import NoContent from './noContentPage.jsx';
import { url } from '../dataFiles/data';

function DistrictPage(){
    const [data,setData]=React.useState([])
    const [loading,setLoading]=React.useState(true)
    const {province,district}=useParams()
    const navigate=useNavigate()
    const furl=url

    const override: CSSProperties = {
        display: "block",
        margin: "100px auto",
        borderColor: "red",
    };

    React.useEffect(()=>{
        const url=furl+province+'/'+district
        setLoading(true)
        axios.get(url).then((data)=>{
            setLoading(false)
            setData(data.data)
        }).catch((err)=>{
            console.log('something went wrong')
        })
    },[province,district])
    const cards=data.map((destination,ind)=>{
        const title=CryptoJS.AES.decrypt(destination.title,'ds23dafg').toString(CryptoJS.enc.Utf8)
        return <DestinationCard clickHandle={()=>{
            const url='/'+province+'/'+district+'/'+title
            //navigate(url,{state: {destination}})
            navigate(url)
            // window.location.reload();
        }} isMobile={isMobile} key={ind} name={title} imageUrl={destination.picture}/>
    })
    return (
        <>
            <HomePageHeader />
            <div className='main-container'>
                <div style={{minHeight: '95vh'}}>
                    <div className='district-cards-place'>
                        {loading?<><HashLoader cssOverride={override}/></>:cards.length===0?<NoContent/>:cards}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default DistrictPage;
import './destinations.css'

function DestinationCard(props) {
  const curl='https://ccbrwoluba.cloudimg.io/'+props.imageUrl.substr(8)+'?w=500&func=fit&bg_color=000'
  return (
    <div className='card' onClick={props.clickHandle}>
      <img className='img-card' src={curl} alt=''/>
      {props.isMobile?<p style={{visibility: 'visible'}} className='text'>{props.name}</p>:<p className='text'>{props.name}</p>}
    </div>
  );
}

export default DestinationCard;

import React, { useState, useRef} from 'react';
import { HiArrowCircleRight, HiArrowCircleLeft  } from "react-icons/hi";
import { Container } from 'react-bootstrap';
import './PopularLocations.css'; // Import your CSS file for styling
import {useNavigate} from 'react-router-dom'

const PopularLocationSlider = (props) => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const navigate=useNavigate();

  const slide = (shift) => {
    scrl.current.scrollBy({
      left: shift,
      behavior: 'smooth'
    });

    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const {locationsData}=props
  return (
    <Container fluid className='my-3 py-3 item-slider-container'>
      <h4 className='px-3 mb-3 item-title'>POPULAR LOCATIONS</h4>
      <div className='item-slider'>
        <div onClick={() => slide(-270)} className={`left-arrow-left ${(scrollX < 1) ? 'is-disabled-hide' : ''}`}>
          <HiArrowCircleLeft size="40px" />
        </div>
        <div ref={scrl} onScroll={scrollCheck} className="item-container"></div>
      <div id='slider' ref={scrl} onScroll={scrollCheck} className="item-container">
        {locationsData.map((location, index) => {
          const curl='https://ccbrwoluba.cloudimg.io/'+location.picture.substr(8)+'?w=500&func=fit&bg_color=000'
          return (
            <div
              onClick={()=>{
                navigate(location.link)
                // window.location.reload();
              }}
              key={location.id}
              className="popular-locations-card">
              <img src={curl} alt={location.title}/>
              <h3>{location.title}</h3>
            </div>
        )})}
      </div>
      <div className={`right-arrow-right ${(!scrollEnd) ? '' : 'is-disabled-hide'}`} onClick={() => slide(+270)}>
          <HiArrowCircleRight size="40px" />
        </div>
      </div>
    </Container>
  );
};

export default PopularLocationSlider;
